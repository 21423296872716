.form {
  text-align: center;
  margin-top: 10px;
}

.form_inputs {
  display: flex;
  flex-direction: column;
  width: 400px;
  /* min-width: 200px; */
  align-items: center;
  padding: 0 50px 50px 50px;
  min-height: 70vh;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: white;
  border-radius: 8px;
}
.navigation_btn {
  margin-bottom: 15px;
}
@media screen and (max-width: 605px) {
  .form_inputs {
    width: 75%;
    margin: 0 auto;
  }
}
.form_inputs_text {
  padding-top: 20px;
  width: 100%;
}
@media screen and (max-width: 605px) {
  .form_inputs_text {
    padding-top: 5px;
  }
}
.form_inputs_text strong {
  font-size: 1.5rem;
  color: #635bff;
}

.form_inputs_text p {
  text-align: start;
  padding-top: 15px;
  font-weight: 300;
  opacity: 0.7;
}
/* .form_button {
  border: 1px solid red;
  background-color: #fff;
  color: red;
  padding: 5px 15px;
  font-size: 25px;
  border-radius: 10px;
  bottom: 10;
} */
@media screen and (max-width: 445px) {
  .form_inputs {
    padding: 20px;
  }
}
.form h3 {
  color: #fff;
  display: none;
}
@media screen and (max-width: 930px) {
  .form h3 {
    display: flex;
    padding: 30px 0;
  }
}
.form_inputs input {
  margin-top: 10px;
  color: #000;
}

.form_inputs button {
  width: 100%;
  background-color: #635bff;
  color: white;
  margin-top: 15px;
  min-width: 200px;
}
.form_input_btn {
  color: white;
}
.form_input_btn:hover {
  background-color: #635bffe5;
  color: white;
}
.form_input_btn_disabled {
  opacity: 0.1;
  background-color: #635bff;
  color: white;
}

/* phone_container */

.phone_container {
  width: 100%;
  min-height: 100px;
  margin-top: 15px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;
}
.phone_container_p {
  background-color: #33313100;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}
.phone_container p {
  font-size: 15px;
  color: #000;
}
/* amount_container */
.amount_container {
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;
}
.phone_container p {
  font-size: 15px;
  color: #000;
}
.phone_container input {
  display: flex;
  align-items: center;
  justify-content: center;
}
.amount_container_p {
  background-color: #33313100;
  padding: 15px;
  border-radius: 5px;
  cursor: pointer;
}
/* school_component */
.school_component {
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;
}

/* student_container */
.student_container {
  width: 100%;
  margin-top: 15px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;
}

/* payment_container */
.payment_container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;
  text-transform: capitalize;
}
/* makePayment_container */
.makePayment_container {
  width: 100%;
  margin: 10px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
}
.makePayment_container strong {
  text-align: right;
  font-size: 30px;
}
.makePayment_container h3 {
  margin-top: 5px;
  display: flex;
  font-size: 40px;
  color: #000;
}
.popupContainer {
  padding: 30px;
  border-radius: 10px;
  padding: 30px;
}
.makePayment_container_btn {
  background-color: lightgray;
  padding: 5px;
}
/* studentPhone_container */
.studentPhone_container {
  margin-top: 15px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 8px;

  width: 100%;
}

.studentPhone_container_btn p {
  font-size: 15px;
  color: #4282ea;
  padding: 5px 0;
  text-align: left;
  margin-top: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.studentPhone_container_btn_line {
  height: 10px;
  width: 100px;
  /* background-color: #b5f8e8S; */
  border-radius: 3px;
  margin-bottom: 10px;
}
.studentPhone_container p:hover {
  color: #00000091;
  transform: translateX(2px); /* Move 2 pixels towards the right on hover */
}
.container_header {
  text-align: left;
  font-size: 20px;
  margin: 10px 0;
  color: #00000080;
  text-transform: uppercase;
}
.form strong {
  text-align: left;
}

/* Define the animation */
/* Define the fade animation */
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 300ms ease-in;
}

/* Defining  the button styles  to be reused in different components */
.button-style {
  background: #635bff;
  color: white;
  opacity: 1;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

/* Apply hover effect */
.button-style:hover {
  background: #635bffe5;
}

/* Apply styles for the disabled button */
.button-style.disabled {
  opacity: 0.7;
  cursor: not-allowed;
}
.labels {
  width: 100%;
  text-align: start;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start; */
}

.labels p {
  font-size: 10px;
  opacity: 0.6;
}
