.navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 30px; */
  height: 100px;
  top: 0;
  z-index: 1;
  position: sticky;
  margin-bottom: 5px;
  background-color: #e6fafb;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
    rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}
.navbar_links {
  width: 90%;
  margin: 0;
  display: flex;
  justify-content: space-between;
  color: #666b7b;
  align-items: center;
}
.navbar_links p {
  font-size: 2rem;
  color: #4282ea;
}
.phone_section {
  height: 100%;
}
.phone_section h4 {
  margin: 10px;
}
.phone_section h5 {
  margin: 10px;
}
@media screen and (max-width: 380px) {
  .navbar_links {
    width: 95%;
  }
  .navbar_links img {
    width: 100px;
  }
}
