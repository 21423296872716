.app {
  width: 100%;
}
.app_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  min-height: 100vh;
  background-color: #dafcfd;
  top: 100px;
}
.app {
  position: relative;
}

.app a {
  text-decoration: none;
}
.app_background_img {
  width: 100%;
  height: 100vh;
  position: relative;
}
.app_background_img img {
  width: 100%;
  height: 100%;
  position: relative;
  /* top: 0; */
  position: fixed;
}
.app_content_icon {
  color: white;
  background-color: #00c307;
  padding: 15px;
  border-radius: 50%;
  transition: all 0.5s ease-in;
  position: sticky;
}
.app_content_whatsApp {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
}
.mobile_app {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 999;
}
.mobile_app img {
  width: 150px;
  object-fit: contain;
  mix-blend-mode: color-burn;
  transition: all 0.5s ease-in;
  position: sticky;
}
.app_content_icon:hover {
  transform: translateX(5px);
}
@media screen and (max-width: 930px) {
  .app_content {
    flex-direction: column;
    padding: 10px;
  }
}
@media screen and (max-width: 600px) {
  .app_content {
    padding: 0px;
  }
}

@media screen and (max-width: 380px) {
  .navbar_links {
    width: 95%;
  }
  /* .mobile_app img {
    width: 70px;
  } */
  .app_content_icon {
    font-size: 0.5rem;
  }
}
